import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  public getToken() {
    return localStorage.getItem('access_token');
  }

  public setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  clear() {
    localStorage.removeItem('access_token');
  }
}
