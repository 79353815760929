import { Component, Inject } from '@angular/core';
import { APP_VERSION } from '@guava-pay/core';

@Component({
  selector: 'gpay-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent {
  today = new Date();

  constructor(@Inject(APP_VERSION) public version: string) {
  }
}
