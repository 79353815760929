import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ErrorModel, handleError, HOSTS, Hosts } from '@guava-pay/core';

@Component({
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.css']
})
export class SupportPage implements OnInit {

  error?: ErrorModel;
  supportForm: FormGroup = this.formBuilder.group({
    message: new FormControl('', [Validators.required])
  });

  constructor(private title: Title,
              private formBuilder: FormBuilder,
              @Inject(HOSTS)private hosts: Hosts,
              private httpClient: HttpClient,
              private uiLoader: NgxUiLoaderService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.title.setTitle('Contact Support | GuavaPay CRMS');
  }

  async submit() {
    try {
      const url = `${this.hosts.crms}/v1/dashboard/support/customer-feedback`;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const params = { 'message': this.supportForm.value.message };

      this.uiLoader.start();
      const response = await this.httpClient.post<any>(url, params, { headers }).toPromise();

      if (!response.success) {
        this.error = {
          title: 'Failed to process your request',
          message: response.message
        };
      } else {
        await this.router.navigateByUrl('/crms/support/thank-you');
      }
    } catch (e) {
      this.error = handleError(e);
    } finally {
      this.uiLoader.stop();
    }
  }
}
