import { Component, Inject, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserToken } from 'libs/layouts/src/lib/models/user';
import { HOSTS, Hosts } from '@guava-pay/core';

@Component({
  selector: 'guava-pay-company-documents',
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.scss']
})
export class CompanyDocumentsComponent implements OnInit {
  selectedFileName: string;
  filesToUpload: File[];
  result: boolean;
  documents: any[] = [
    { label: 'Company Logo', value: 'co_logo', format: 'image/*', fileName: '' },
    { label: 'CR14', value: 'CR14', format: '.pdf' },
    { label: 'Proof Of Company Address', value: 'proof_of_company_address', format: '.pdf' },
    { label: 'Articles Of Association', value: 'articles_of_association', format: '.pdf' },
    { label: 'Tax Clearance', value: 'tax_clearance', format: '.pdf' },
    { label: 'Certificate Of Incorporation', value: 'cert_of_incorporation', format: '.pdf' },
    { label: 'Contact Details Of Directors', value: 'contact_details_of_directors', format: '.pdf' },
    { label: 'Ids Of Directors', value: 'ids_of_directors', format: '.pdf' },
    { label: 'Proof Of Residence For Directors', value: 'proof_of_residence_for_directors', format: '.pdf' },
    { label: 'Passport Photos Of Directors', value: 'passport_photos_of_directors', format: '.pdf' },
  ];

  form: FormGroup = this.formBuilder.group({
    co_logo: new FormControl('', [Validators.required]),
    CR14: new FormControl('', [Validators.required]),
    proof_of_company_address: new FormControl('', [Validators.required]),
    articles_of_association: new FormControl('', [Validators.required]),
    tax_clearance: new FormControl('', [Validators.required]),
    cert_of_incorporation: new FormControl('', [Validators.required]),
    contact_details_of_directors: new FormControl('', [Validators.required]),
    ids_of_directors: new FormControl('', [Validators.required]),
    proof_of_residence_for_directors: new FormControl('', [Validators.required]),
    passport_photos_of_directors: new FormControl('', [Validators.required]),
  });
  constructor(private formBuilder: FormBuilder,
    private uiLoader: NgxUiLoaderService,
    private http: HttpClient,
    @Inject(HOSTS) private hosts: Hosts,
    private jwtHelperService: JwtHelperService,
    private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.getMyDocuments();
  }

  getMyDocuments = async () => {
    const url = `${this.hosts.crms}/v1/document/get-all-by-company`;
    const documents: any = await this.http.get(url).toPromise();
    this.documents = this.documents.map(doc => {
      const contentItem = documents.content.find(item => item.type === doc.value);

      if (contentItem) {
        return {
          label: doc.label,
          value: doc.value,
          status: contentItem.status,
          persistanceDates: contentItem.persistanceDates,
        };
      } else return doc;
    });


  }

  setFile(event: any) {
    const file = event.target.files ? event.target.files[0] : null;
    const name = event.target.id;
    const filename = event.target.files ? event.target.files[0].name : null;
    this.documents.find(d => d.value === name).fileName = filename;
    this.form.get(name)?.setValue(file);
  }

  public async save(): Promise<void> {
    try {
      this.uiLoader.start();
      const url = `${this.hosts.crms}/v1/document/create`;

      for (const controlKey in this.form.controls) {
        if (this.form.controls.hasOwnProperty(controlKey)) {
          const userToken: UserToken | any = this.jwtHelperService.decodeToken();
          const companyId = userToken.user?.company?.id;
          const params = new HttpParams()
            .append('companyId', companyId)
            .append('type', controlKey);

          const formData = new FormData();
          const file = this.form.controls[controlKey].value;
          formData.append('file', file);
          await this.http.post(url, formData, { params }).toPromise();
        }
      }
      this.result = true;
    } catch (error) {
      this.logger.error('Error uploading documents', error);
    } finally {
      this.uiLoader.stop();
    }
  }

}
