import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HOSTS, Hosts } from '@guava-pay/core';
import { map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { saveAs } from 'file-saver';

@Component({
  selector: 'gpay-ssb-batches-by-status',
  templateUrl: './ssb-batches-by-status.component.html',
  styleUrls: ['./ssb-batches-by-status.component.scss'],
})
export class SsbBatchesByStatusComponent implements OnInit {
  constructor(
    @Inject(HOSTS) private hosts: Hosts,
    private httpClient: HttpClient,
    private uiLoader: NgxUiLoaderService
  ) {}
  error?: string;
  loading = false;
  stats: { [status: string]: number };

  selectedStatus?: string;

  ngOnInit(): void {
    const url = `${this.hosts.crms}/v1/dashboard/ssb/batch/get-by-processing-status`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loading = true;
    this.httpClient
      .get<any>(url, { headers })
      .toPromise()
      .then(
        (response) => {
          this.loading = false;
          this.stats = Object.assign(
            {
              SUBMITTED: 0,
              CLOSED: 0,
            },
            response.result
          );
        },
        (reason) => {
          this.loading = false;
          this.error = reason;
        }
      );
  }

  async downloadAll() {
    const url = `${this.hosts.crms}/v1/downloads/applications/get-all-to-text-file`;
    try {
      this.uiLoader.start();
      const response = await this.httpClient
        .get(url, { observe: 'response', responseType: 'blob' })
        .toPromise();

      this.saveToFileSystem(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.uiLoader.stop();
    }
  }

  private saveToFileSystem(response: HttpResponse<Blob>) {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const contentType = response.headers.get('Content-Type') || 'application/octet-stream;';


    const filename = this.getFileName(contentDispositionHeader);

    const blob = new Blob([response.body], { type: contentType });
    saveAs(blob, filename);
  }
  
  private getFileName(contentDispositionHeader: string) {
    if (!contentDispositionHeader || contentDispositionHeader === '') {
      return 'File';
    }
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    return filename;
  }
}
