import { Component, OnInit, Inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Title } from '@angular/platform-browser';
import { User, UserToken } from '../../models/user';
import { HOSTS, Hosts } from '@guava-pay/core';

@Component({
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit {

  user: User;
  imageUrl: string;

  constructor(private title: Title,
              @Inject(HOSTS)private hosts: Hosts,
              private jwtHelperService: JwtHelperService) {
  }

  ngOnInit(): void {
    this.title.setTitle('Profile | GuavaPay CRMS');

    const userToken: UserToken | any = this.jwtHelperService.decodeToken();
    this.user = userToken.user;
    this.imageUrl = this.getImageUrl(userToken.user);
  }

  getImageUrl(user: User): string {
    if (user.imageUrl && user.imageUrl !== '')
      return `${this.hosts.crms}/v1${user.imageUrl}`;
    return null;
  }
}
