<div class="w-100 flex-grow-1 bg-primary p-5">
  <div class="container text-white small">
    <div class="row">
      <div class="col-sm-5">
        <ul class="list-unstyled">
          <li>
            <div class="d-inline-flex">
              <a class="navbar-brand" routerLink="/">
                <img src="assets/images/guavatech-logo-full -white-on-green.png" alt="@guavapay"/>
                <strong style="font-weight: 500; font-size: 11px;color: white;">CRMS</strong>
              </a>
            </div>
          </li>
          <li>
            <span class="small px-2">&copy;&nbsp;&nbsp;GuavaPay {{ today | date : 'yyyy' }}</span>
          </li>
          <li>
            <span class="small px-2 app-version-number">Build {{ version }}</span>
          </li>
        </ul>
      </div>
      <div class="col-sm-2">
        <ul class="list-unstyled">
          <li><a class="text-white text-decoration-none" href=" https://guavatelecom.com/about/">About Us</a></li>
          <li><a class="text-white text-decoration-none" href="https://guavatelecom.com/contact/">Contact</a></li>
          <li><a class="text-white text-decoration-none" href="#">Terms & Conditions</a></li>
        </ul>
      </div>
      <div class="col-sm-2">
        <ul class="list-unstyled">
          <li>
            <a class="text-white text-decoration-none" href="https://www.facebook.com/guavatelecoms" target="_blank">
              <i class="fa fa-fw fa-facebook-f"></i>
              Facebook
            </a>
          </li>
          <li>
            <a class="text-white text-decoration-none" href="https://twitter.com/GroupGuava" target="_blank">
              <i class="fa fa-fw fa-twitter"></i>
              Twitter
            </a>
          </li>
          <li>
            <a class="text-white text-decoration-none" href="https://www.instagram.com/guavaafrica" target="_blank">
              <i class="fa fa-fw fa-instagram"></i>
              Instagram
            </a>
          </li>
          <li>
            <a class="text-white text-decoration-none" href="https://www.linkedin.com/in/guava-africa-4a85321a9/"
               target="_blank">
              <i class="fa fa-fw fa-linkedin"></i>
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="subscribeToNewsletter">Subscribe to our newsletter</label>
          <div class="input-group">
            <input id="subscribeToNewsletter" class="form-control bg-transparent border-light text-white"
                   placeholder="Email Address"/>
            <div class="input-group-append">
              <button class="btn btn-light btn-outline-light text-primary" type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
