import { TokenService } from './token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  constructor(private tokenService: TokenService,
              private jwtHelperService: JwtHelperService) {
  }

  public isAuthenticated(): boolean {
    const token = this.tokenService.getToken();
    return token && !this.jwtHelperService.isTokenExpired(token);
  }
}
