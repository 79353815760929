import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guava-pay/core';
import {
  FullWidthContainerComponent,
  ProfilePage,
  ProfilePhotoPage,
  SideBarContainerComponent
} from '@guava-pay/layouts';
import { HomePage } from './pages/home/home.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SupportPage } from './pages/support/support.page';
import {
  SupportMessageAcknowledgementPage
} from './pages/support-message-acknowledgement/support-message-acknowledgement.page';
import { SelectModulePage } from './pages/select-module/select-module.page';
import { ChangePasswordPage } from '../../../../libs/layouts/src/lib/pages/change-password/change-password.page';
import { CompanyDocumentsComponent } from './pages/company-documents/company-documents.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@guava-pay/auth').then(m => m.AuthModule)
  },
  {
    path: 'mobile-registration',
    loadChildren: () =>
      import('@guava-pay/mobile-registration').then(
        m => m.MobileRegistrationModule
      )
  },
  {
    path: 'crms',
    component: SideBarContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardPage },
      {path: 'documents', component: CompanyDocumentsComponent},
      { path: 'profile/photo', component: ProfilePhotoPage },
      { path: 'profile/change-password', component: ChangePasswordPage },
      { path: 'profile', pathMatch: 'full', component: ProfilePage },
      {
        path: 'support/thank-you',
        component: SupportMessageAcknowledgementPage
      },
      { path: 'support', pathMatch: 'full', component: SupportPage },
      {
        path: 'batches',
        loadChildren: () =>
          import('@guava-pay/batches').then(m => m.BatchesModule)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('@guava-pay/user-management').then(
            m => m.UserManagementModule
          ),
        canLoad: [NgxPermissionsGuard],
        data: {
          permissions: {
          
            hasPermissions: ['SSB_ADMIN','ADMIN'],
            redirectTo: '/crms/dashboard'
          }
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('@guava-pay/reports').then(m => m.ReportsModule)
      }
    ]
  },
  {
    path: '',
    component: FullWidthContainerComponent,
    children: [
      { path: '', redirectTo: 'get-started', pathMatch: 'full' },
      { path: 'home', component: HomePage },
      { path: 'get-started', component: SelectModulePage }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
