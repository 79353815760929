import { ErrorModel } from './error.model';
import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (error: Error): ErrorModel => {
  if (error instanceof HttpErrorResponse) {
    return handleHttpError(error);
  } else {
    return handleErrorEvent(<any>error);
  }
};

const handleHttpError = (error: HttpErrorResponse): ErrorModel => {
  switch (error.status) {
    case 0: {
      return {
        title: 'Failed to connect to the server',
        message: 'Could not establish a connection to our server. Please try again in a bit'
      };
    }

    case 401: {
      return {
        title: 'You are not authorized to perform this action',
        message: 'Please login and try again. If the error continues, contact your system administrator.'
      };
    }

    case 500: {
      return {
        title: 'There was a problem while processing your request',
        message: 'Please try again later, or contact your system administrator'
      };
    }

    default: {
      return {
        title: 'Failed to execute your request',
        message: 'Please try again later, or contact your system administrator'
      };
    }
  }
};

const handleErrorEvent = (error: ErrorEvent): ErrorModel => {
  return {
    title: 'A problem is preventing your request from being executed',
    message: 'Please contact your system administrator'
  };
};
