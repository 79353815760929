import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePage } from './profile/profile.page';
import { ProfilePhotoPage } from './profile-photo/profile-photo.page';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChangePasswordPage } from './change-password/change-password.page';

@NgModule({
  declarations: [ProfilePage, ProfilePhotoPage, ChangePasswordPage],
  imports: [
    CommonModule,
    ImageCropperModule,
    FormsModule,
    RouterModule,
    TooltipModule,
    ReactiveFormsModule
  ],
  exports: [
    ProfilePage,
    ProfilePhotoPage
  ]
})
export class PagesModule {
}
