<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-3 align-items-center">
          <h4 class="flex-grow-1">Profile</h4>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="user; else notFoundView">
      <div class="col-12 mb-2">
        <div class="w-100 d-flex flex-column align-items-start">
          <img [src]="imageUrl" alt="No Profile Pic Loaded" [class.p-5]="!imageUrl && imageUrl !==''"
               class="bg-dark text-white img-thumbnail img-fluid border border-white">

          <a class="btn btn-primary px-4 my-2 text-white" routerLink="/crms/profile/photo">
            Upload Image
          </a>

        </div>
      </div>
      <div class="col-12">
        <h5 class="text-dark">{{ user.fullname }}</h5>
        <p class="text-muted" *ngFor="let role of user.roles">{{ role.name }}</p>
        <div class="row">
          <!-- User name -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Username
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.username }}
              </div>
            </div>
          </div>

          <!-- Company Name -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Company Name
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.company?.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Phone -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Contact Cell
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.contactDetails.phone }}
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Email
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.contactDetails.email }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Address -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Address
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.contactDetails.address }}
              </div>
            </div>
          </div>

          <!-- Function -->
          <div class="form-group col-6">
            <div class="input-group input-group-lg rounded-0 border border-primary w-100">
              <div class="input-group-prepend w-25 px-0  bg-primary text-left small rounded-0">
                    <span class="input-group-text pr-0 rounded-0 border-0 text-white small text-uppercase">
                      Function
                    </span>
              </div>
              <div class="w-75 px-3 text-dark py-2">
                {{ user.function }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #notFoundView>
      <div class="row position-relative">
        <div class="alert alert-warning">
          <h4 class="alert-heading">Failed to load your profile</h4>
          <p>Your session might have expired. Please re-log in and try again</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
