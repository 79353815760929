import { Component, Inject } from '@angular/core';
import { APP_VERSION } from '@guava-pay/core';

@Component({
  selector: 'gpay-side-bar-footer',
  templateUrl: './side-bar-footer.component.html',
  styleUrls: ['./side-bar-footer.component.scss']
})
export class SideBarFooterComponent {
  constructor(@Inject(APP_VERSION) public version: string) {
  }
}
