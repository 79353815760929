import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './select-module.page.html',
  styleUrls: ['./select-module.page.scss'],
  animations: [
    trigger('bubble', [
      transition(':enter', [
        style({
          height: 0,
          width: 0,
          left: 0,
          top: '50%'
        }),
        animate('1s')
      ])
    ])
  ]
})
export class SelectModulePage implements OnInit {

  constructor(private title: Title) {
  }

  ngOnInit(): void {
    this.title.setTitle('Select Module | GuavaPay CRMS');
  }

}
