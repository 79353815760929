<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-5 align-items-center">
          <h4 class="flex-grow-1">Contact Our Support Team</h4>
        </div>
        <div class="w-100">
          <div class="container-fluid">
            <div class="row">
              <form [formGroup]="supportForm" class="col-xl-6 col-lg-8 col-sm-12" (ngSubmit)="submit()">
                <div class="form-group w-100">
                  <label class="w-100">Enter your message
                    <textarea name="message" class="form-control" formControlName="message"></textarea>
                  </label>
                </div>
                <div class="form-group">
                  <button class="btn btn-secondary text-white px-4" type="submit"
                          [disabled]="supportForm.pristine || supportForm.invalid">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="error">
              <div class="col">
                <div class="alert alert-warning small" *ngIf="error">
                  <div class="w-100 d-flex">
                    <h6 class="alert-heading flex-grow-1 align-items-start">{{error.title}}</h6>
                    <button (click)="error = null" type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <p>{{ error.message }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
