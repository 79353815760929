<div class="d-flex flex-column flex-grow-1 p-3">
    <div class="py-2">
        <p class="lead page-instruction">
            Once we have received your documentation. In line with International Anti-money Laundering Laws (AML), we
            shall
            review your documents within the next 24 hours. Upon successful review, your account shall be activated, and
            you shall receive notification accordingly.
        </p>
    </div>
    <div class="flex-grow-1 d-flex flex-column align-items-stretch position-relative">
        <form [formGroup]="form" class="container-fluid m-0 p-0">
            <div class="row no-gutters">
                <div class="col-4" *ngFor="let document of documents">

                    <div class="card mt-3"
                        [ngClass]="{'available': document.status, 'not-available': !document.status}">
                        <div class="card-body">
                            <div class="form-group">
                                <label for={{document.value}}> <strong>{{document.label}}</strong></label> <br>
                                <span *ngIf="document.fileName"><small><i>{{document.fileName ||
                                            ''}}</i></small><br></span>
                                <small *ngIf="document.status"><span
                                        class="badge badge-pill badge-primary">{{document.status}}</span></small>
                                <small *ngIf="!document.status"><span class="badge badge-pill badge-danger">Not
                                        uploaded</span></small>
                                <br>
                                <div class="btn-group">
                                    <label type="button" class="btn btn-primary" mat-raised-button btnCheckbox
                                        tabindex="0" role="button" (click)="fileInput.click()">Upload Document</label>
                                    <label type="button" class="btn btn-secondary" mat-raised-button btnCheckbox
                                        tabindex="1" hidden role="button">View Document</label>
                                </div>
                                <input type="file" hidden #fileInput class="form-control-file" id={{document.value}}
                                    formControlName={{document.value}} (change)="setFile($event)"
                                    accept={{document.format}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row no-gutters mt-3 mb-5">
                <div class="col pb-5">
                    <button type="button" class="btn btn-primary px-5" (click)="save()"
                        [disabled]="form.pristine || form.invalid">
                        Save
                    </button> <br> <br>
                    <span *ngIf="result" class="mt-4" style="color: #8dc63f;">Your documents have been uploaded !</span>
                </div>
            </div>
        </form>
    </div>
</div>