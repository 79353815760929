<div class="w-100 p-5">
<h6 class=" text-white">CRMS</h6>
  <ul class="list-inline list-unstyled">
    <li class="list-inline-item">
      <a class="text-white" href="https://www.instagram.com/guavaafrica" target="_parent"
      ><i class="fa fa-fw fa-instagram"></i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="text-white" href="https://twitter.com/GroupGuava" target="_parent">
        <i class="fa fa-fw fa-twitter"></i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="text-white" href="https://www.facebook.com/guavatelecoms" target="_parent">
        <i class="fa fa-fw fa-facebook-f"></i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="text-white" href="https://www.linkedin.com/in/guava-africa-4a85321a9/"
         target="_blank">
        <i class="fa fa-fw fa-linkedin"></i>
      </a>
    </li>
  </ul>
  <p class="app-version-number app-version-number-small">Build {{version}}</p>
</div>
