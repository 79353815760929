import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyBatchesByStatusComponent } from './company-batches-by-status/company-batches-by-status.component';
import { NgPipesModule } from 'ngx-pipes';
import { CompanyBatchesByRecordCountComponent } from './company-batches-by-record-count/company-batches-by-record-count.component';
import { CompanyBatchesByDateComponent } from './company-batches-by-date/company-batches-by-date.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SsbBatchesByStatusComponent } from './ssb-batches-by-status/ssb-batches-by-status.component';
import { SsbThroughputByDateComponent } from './ssb-throughput-by-date/ssb-throughput-by-date.component';
import { SsbThroughputByCompanyComponent } from './ssb-throughput-by-company/ssb-throughput-by-company.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    CompanyBatchesByStatusComponent,
    CompanyBatchesByRecordCountComponent,
    CompanyBatchesByDateComponent,
    SsbBatchesByStatusComponent,
    SsbThroughputByDateComponent,
    SsbThroughputByCompanyComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    NgxChartsModule,
    ProgressbarModule,
    PopoverModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    CompanyBatchesByStatusComponent,
    CompanyBatchesByRecordCountComponent,
    CompanyBatchesByDateComponent,
    SsbBatchesByStatusComponent,
    SsbThroughputByDateComponent,
    SsbThroughputByCompanyComponent
  ]
})
export class WidgetsModule {
}
