import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { SideBarFooterComponent } from './components/side-bar-footer/side-bar-footer.component';
import { FullWidthContainerComponent } from './components/full-width-container/full-width-container.component';
import { SideBarContainerComponent } from './components/side-bar-container/side-bar-container.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UserBadgeComponent } from './components/user-badge/user-badge.component';
import { PagesModule } from './pages/pages.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule,
    PagesModule,
    NgxPermissionsModule
  ],
  declarations: [
    MainFooterComponent,
    SideBarFooterComponent,
    FullWidthContainerComponent,
    SideBarContainerComponent,
    SideBarComponent,
    TopMenuComponent,
    UserBadgeComponent
  ],
  exports: [
    MainFooterComponent,
    SideBarFooterComponent,
    FullWidthContainerComponent,
    SideBarContainerComponent,
    SideBarComponent
  ]
})
export class LayoutsModule {
}
